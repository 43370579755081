import { Column, flexRender, Row } from '@tanstack/react-table';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import React, { CSSProperties, ReactNode } from 'react';
import { getCommonPinningStyles } from '../utils';

interface TableBodyProps<T> {
	virtualizedRows: VirtualItem[];
	rows: Row<T>[];
	rowVirtualizer: Virtualizer<Element, Element>;
	options?: (entry: T) => ReactNode;
	onRowClick?: (entry: T) => void;
	select?: boolean;
	selectedRows: Row<T>[];
	setSelectedRows: React.Dispatch<React.SetStateAction<Row<T>[]>>;
}

const TableBody = <T,>({ rows, virtualizedRows, rowVirtualizer, options, onRowClick, select, setSelectedRows, selectedRows }: TableBodyProps<T>) => {

	return (
		<tbody
			className={'divide-y divide-neutral-80 '}
			style={{
				display: 'grid',
				height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
				position: 'relative', //needed for absolute positioning of rows
			}}>
			{virtualizedRows.map((virtualItem, index) => {
				const row = rows[virtualItem.index];
				return (
					<tr
						onClick={() => onRowClick && onRowClick(row.original)}
						data-index={virtualItem.index}
						ref={node => rowVirtualizer.measureElement(node)}
						key={virtualItem.key}
						style={{
							display: 'flex',
							position: 'absolute',
							top: `${virtualItem.start}px`, //this should always be a `style` as it changes on scroll
							width: '100%',

						}}
						className={`bg-neutral-90 items-center ${onRowClick ? 'cursor-pointer hover:bg-neutral-80' : ''}`}>
						{select
							? <td className='w-10 flex justify-center'>
								<input
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedRows((prev) => [...prev, row]);
										} else {
											setSelectedRows((prev) => prev.filter(r => r.id !== row.id));
										}
									}}
									checked={selectedRows.some(r => r.id === row.id)}
									type='checkbox'></input>
							</td>
							: null}
						{row.getVisibleCells().map(cell => (
							<td key={cell.id} className={`px-2 min-h-12 items-center transition duration-200 ${getCommonPinningStyles(cell.column, true).className}`}

								style={{
									display: 'flex',
									width: cell.column.getSize(),
									...getCommonPinningStyles(cell.column, true).style,
								}}>
								<div className={'truncate'}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</div>
							</td>
						))}

					</tr>
				);
			})}
		</tbody>
	);
};

export default TableBody;