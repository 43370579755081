import React from 'react';
import { Column, flexRender, HeaderGroup } from '@tanstack/react-table';
import { CSSProperties, ReactNode } from 'react';
import { ArrowLeftIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { ColumnType } from '../../Types';
import { getCommonPinningStyles } from '../../utils';
import { Bars3BottomRightIcon } from '@heroicons/react/16/solid';
import ColumnResizer from './components/ColumnResizer/ColumnResizer';

interface TableHeaderProps<T> {
	headerGroups: HeaderGroup<T>[];
	select?: boolean
	headerClass: string | undefined;
}

type MetaType<T> = ColumnType<T>;

const TableHeader = <T,>({ headerGroups, headerClass, select }: TableHeaderProps<T>) => {
	return (
		<thead className={`text-neutral-90 sticky z-10 top-0 rounded-t overflow-clip ${headerClass}`}>
			{headerGroups.map(headerGroup => (
				<tr
					key={headerGroup.id}
					style={{ display: 'flex', width: '100%' }}
				>
					{select ? <td className='w-10'></td> : null}
					{headerGroup.headers.map(header => {
						return (
							<th
								key={header.id}
								colSpan={header.colSpan}
								className={`font-normal text-left px-2 py-2 border-none border-neutral-70 bg-primary-40 ${getCommonPinningStyles(header.column).className}`}
								style={{
									display: 'flex',
									width: header.getSize(),
									...getCommonPinningStyles(header.column).style as CSSProperties,
								}}
							>

								<div className={'flex w-full justify-between items-center h-fit'}>
									<div className={'truncate'}>
										{
											flexRender(header.column.columnDef.header, header.getContext())
										}
									</div>
									<div className={'h-fit inline-flex'}>
										{header.column.getCanSort() ?
											<button className={'p-1'} onClick={header.column.getToggleSortingHandler()}>
												{{
													asc: <ChevronDoubleUpIcon className={'h-3 aspect-square'} />,
													desc: <ChevronDoubleDownIcon className={'h-3 aspect-square'} />,
												}[header.column.getIsSorted() as string] ??
													<Bars3BottomRightIcon className={'h-3 aspect-square'} />}
											</button>
											: null
										}
										{header.column.getCanPin() ? <button
											className="p-1"
											onClick={() => {
												if (header.column.getIsPinned()) {
													header.column.pin(false);
													return;
												}
												header.column.pin('left');
											}}
										>
											<svg className={`h-3 aspect-square w-fit ${header.column.getIsPinned() ? 'fill-primary-60' : 'fill-neutral-90'}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="122.879px" height="122.867px" viewBox="0 0 122.879 122.867" enableBackground="new 0 0 122.879 122.867" xmlSpace="preserve"><g><path fillRule="evenodd" clipRule="evenodd" d="M83.88,0.451L122.427,39c0.603,0.601,0.603,1.585,0,2.188l-13.128,13.125 c-0.602,0.604-1.586,0.604-2.187,0l-3.732-3.73l-17.303,17.3c3.882,14.621,0.095,30.857-11.37,42.32 c-0.266,0.268-0.535,0.529-0.808,0.787c-1.004,0.955-0.843,0.949-1.813-0.021L47.597,86.48L0,122.867l36.399-47.584L11.874,50.76 c-0.978-0.98-0.896-0.826,0.066-1.837c0.24-0.251,0.485-0.503,0.734-0.753C24.137,36.707,40.376,32.917,54.996,36.8l17.301-17.3 l-3.733-3.732c-0.601-0.601-0.601-1.585,0-2.188L81.691,0.451C82.295-0.15,83.279-0.15,83.88,0.451L83.88,0.451z" /></g></svg>
										</button>
											: null
										}
									</div>
								</div>

								{header.column.getCanResize()
									? <ColumnResizer header={header} />
									: null
								}

							</th>
						);
					})}
				</tr>
			))}
		</thead>
	);
};

export default TableHeader;
