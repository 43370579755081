/**
 * Endpoints used to make API calls
 *
 * There are currently have two BASE_URLs:
 * Production API and Developer API, imported from environment variables.
 *
 */

import { createObservation, createTrainingObservation } from '@modules/perform/api';
import { updatePickupZone } from '@modules/ride/api';
import { group, profile } from 'console';
import dotenv from 'dotenv';

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const authEndpoints = {
    refresh: `${REACT_APP_BASE_URL}api/v2/auth/token/refresh/`,
    login: `${REACT_APP_BASE_URL}api/v2/auth/login/`,
    updatePassword: `${REACT_APP_BASE_URL}api/v2/users/force-password-change/`,
};

export const userEndpoints = {
    user: `${REACT_APP_BASE_URL}api/v2/users/my-account`,
    requestPasswordReset: `${REACT_APP_BASE_URL}api/v2/users/password/request-reset`,
    passwordResetToken: `${REACT_APP_BASE_URL}api/v2/users/password/verify-reset`,
    resetPassword: `${REACT_APP_BASE_URL}api/v2/users/password/reset`,
    register: (email: string, theme = 'light') => `${REACT_APP_BASE_URL}api/v2/users/new?email=${email}`,
    confirmRegistration: (code: string) => `${REACT_APP_BASE_URL}api/v2/users/new?registration_code=${code}`,
};

// Endpoints used within the directory app
export const directoryEndpoints = {
    directory: () => `${REACT_APP_BASE_URL}api/v2/directory/`,
    directoryGroups: () => `${directoryEndpoints.directory()}groups/`,
    directoryMembers: (groupId: number) => `${directoryEndpoints.directoryGroups()}${groupId}/`,

    fetchDirectoryGroup: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/directory/groups/${groupId}`,
    myContacts: (daysBack?: number) => `${REACT_APP_BASE_URL}api/v2/user/my-contacts${daysBack ? `?recent=${daysBack}` : ''}`,
    myContact: (contactId: number) => `${REACT_APP_BASE_URL}api/v2/user/my-contacts/${contactId}`,
    profileCard: (profileId: number) => `${REACT_APP_BASE_URL}api/v2/organizations/profiles/${profileId}/card/`,
    profileCardSensitive: (profileId: number) => `${directoryEndpoints.profileCard(profileId)}sensitive/`,

    recentContacts: () => `${REACT_APP_BASE_URL}api/v2/organizations/recent-contacts/`,

    viewableDirectories: () => `${REACT_APP_BASE_URL}api/v2/organizations/viewable-directories/`,
    userDirectories: () => `${REACT_APP_BASE_URL}api/v2/organizations/user-directories/`,
    orgDirectories: () => `${REACT_APP_BASE_URL}api/v2/organizations/organization-directories/`,

    organizationDirectoryProfiles: (organizationId: number) =>
        `${REACT_APP_BASE_URL}api/v2/organizations/organization-directories/${organizationId}/`,
};

// Endpoints used within perform
export const performEndpoints = {
    // publishObservation: (id: number) => `${REACT_APP_BASE_URL}api/perform/publishObservation/${id}`,
    // unpublishObservation: (id: number) => `${REACT_APP_BASE_URL}api/perform/unpublishObservation/${id}`,
    observationList: `${REACT_APP_BASE_URL}api/perform/observationList/`,
    // deleteObservation: (id: number) => `${REACT_APP_BASE_URL}api/perform/deleteObservation/${id}`,
    observableProfiles: `${REACT_APP_BASE_URL}api/perform/observableProfiles/`,
    availableTemplates: `${REACT_APP_BASE_URL}api/perform/availableTemplates/`,
    newObservation: `${REACT_APP_BASE_URL}api/perform/newObservation/`,

    perform: () => `${REACT_APP_BASE_URL}api/v2/perform/`,

    performGroups: () => `${performEndpoints.perform()}groups/`,
    performGroup: (groupId: number) => `${performEndpoints.performGroups()}${groupId}`,

    performGroupMemberships: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/members/`,
    performGroupMembership: (groupId: number, membershipId: number) =>
        `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/members/${membershipId}/`,
    eligibleMemberships: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/members/eligible/`,

    createPerformGroupMembership: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/members/new/`,

    performOrgs: () => `${performEndpoints.perform()}organizations/`,
    performOrg: (orgId: number) => `${performEndpoints.performOrgs()}${orgId}`,

    observations: (groupId: number, type: string, resource: 'groups' | 'organizations', filter: 'published' | 'drafts' | 'training') =>
        `${performEndpoints.perform()}${resource}/${groupId}/observations/${type}/${filter}`,

    createObservation: (type: string) => `${performEndpoints.perform()}observations/${type}/new/`,

    createTrainingObservation: (type: string) => `${performEndpoints.createObservation(type)}training/`,

    createSelfObservation: (type: string) => `${performEndpoints.createObservation(type)}self/`,

    createDirectReportObservation: (type: string) => `${performEndpoints.createObservation(type)}`,

    templates: (groupId: number, type: string, resource: 'groups' | 'organizations') =>
        `${performEndpoints.perform()}${resource}/${groupId}/observations/${type}/new/`,

    profilesFromTemplate: (type: string, templateId: number) => `${performEndpoints.perform()}observations/${type}/new/${templateId}/profiles/`,

    observation: (type: string, observationId: number) => `${performEndpoints.perform()}observations/${type}/${observationId}/`,

    scoreObservation: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}score/`,

    field: (type: string, observationId: number, fieldId: number) => `${performEndpoints.observation(type, observationId)}fields/${fieldId}/`,

    fieldNano: (type: string, observationId: number, fieldId: number) => `${performEndpoints.field(type, observationId, fieldId)}nano/`,

    subsectionNano: (type: string, observationId: number, subsectionId: number) =>
        `${performEndpoints.observation(type, observationId)}subsections/${subsectionId}/nano/`,

    observationDate: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}observed-date/`,

    publishObservation: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}publish/`,

    finalNotes: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}final-notes/`,
    unpublishObservation: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}unpublish/`,

    deleteObservation: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}`,

    observationFormalityTypes: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}formality-type/`,

    observationHistory: (groupId: number, type: string, profileId: number, templateId: number) =>
        `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/observations/${type}/history/${profileId}/templates/${templateId}/`,

    observationSectionHistory: (groupId: number, type: string, profileId: number, templateId: number, sectionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/observations/${type}/history/${profileId}/templates/${templateId}/sections/${sectionId}/`,

    observationSubsectionHistory: (groupId: number, type: string, profileId: number, templateId: number, sectionId: number, subsectionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/perform/groups/${groupId}/observations/${type}/history/${profileId}/templates/${templateId}/sections/${sectionId}/subsections/${subsectionId}/`,

    allowContributors: (type: string, observationId: number) => `${performEndpoints.observation(type, observationId)}allow-contributors/`,

    selfObservationTemplates: (type: string) => `${performEndpoints.createObservation(type)}self/`,

    publishedObservationsAsSubject: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/as-subject/`,
    allObservationsAsObserver: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/as-observer/`,
    publishedObservationsAsObserver: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/as-observer/published/`,
    draftObservationsAsObserver: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/as-observer/drafts/`,
    directReportObservations: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/my-direct-reports/`,

    directReportProfiles: () => `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/my-direct-reports/profiles/`,
    directReportPGOs: (directReportProfileId: number) =>
        `${REACT_APP_BASE_URL}api/v2/perform/observations/profiles/my-direct-reports/${directReportProfileId}/pgos/`,
};

// Endpoints used within magnify
export const magnifyEndpoints = {
    // dashboardCard endpoint is not being used because the endpoint comes from the concatenation of the card slider property on the DashboardItem and the ID of the student
    dashboardList: `${REACT_APP_BASE_URL}api/magnify/dashboards/`,
    dashboard: `${REACT_APP_BASE_URL}api/magnify/dashboard/`,
    dashboardCardStatus: (studentId: number) => `${REACT_APP_BASE_URL}api/v2/magnify/students/cards/${studentId}/status`,
    dashboardCardGrades: (studentId: number) => `${REACT_APP_BASE_URL}api/v2/magnify/students/cards/${studentId}/grades`,
    dashboardCardBehavior: (studentId: number) => `${REACT_APP_BASE_URL}api/v2/magnify/students/cards/${studentId}/behavior`,
    dashboardCardAssessments: (studentId: number) => `${REACT_APP_BASE_URL}api/v2/magnify/students/cards/${studentId}/assessments`,
    recentDashboards: `${REACT_APP_BASE_URL}api/magnify/recentDashboards/`,

    magnify: () => `${REACT_APP_BASE_URL}api/v2/magnify/`,
    magnifyDataset: (dataset: 'students' | 'staff' | 'groups') => `${magnifyEndpoints.magnify()}${dataset}/`,
    magnifyCollection: (dataset: 'students' | 'staff' | 'groups', collection: 'orgs' | 'schools' | 'classrooms', collectionId: number) =>
        `${magnifyEndpoints.magnifyDataset(dataset)}${collection}/${collectionId}/`,

    magnifyData: (dataset: 'students' | 'staff' | 'groups', collection: 'orgs' | 'schools' | 'classrooms', collectionId: number, config: string) =>
        `${magnifyEndpoints.magnifyCollection(dataset, collection, collectionId)}${config}/data/`,
};

// Endpoints used within announce
export const announceEndpoints = {
    me: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${groupId}/members/me/permissions`,
    announcements: `${REACT_APP_BASE_URL}api/v2/announce/users/feed/`,
    savedAnnouncements: `${REACT_APP_BASE_URL}api/v2/announce/users/saved/`,
    announceGroups: `${REACT_APP_BASE_URL}api/v2/announce/groups/`,

    announceGroup: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}`,

    announceGroupMembers: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/`,
    announceGroupEligibleMembers: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/eligible/`,
    addAnnounceGroupMember: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/new/`,
    announceGroupMember: (announceGroupId: number, announceMembershipId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/members/${announceMembershipId}/`,

    announceGroupAnnouncements: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements`,
    createAnnounceGroupAnnouncement: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/new/`,
    appendAnnouncementImage: (announceGroupId: number, announcementId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/update-img/`,
    announceGroupAnnouncement: (announceGroupId: number, announcementId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/`,

    removeAnnouncementImage: (announceGroupId: number, announcementId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/remove-img/`,

    approveAnnouncement: (announceGroupId: number, announcementId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/approve/`,
    rejectAnnouncement: (announceGroupId: number, announcementId: number) =>
        `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/announcements/${announcementId}/reject/`,

    announceGroupAudience: (announceGroupId: number) => `${REACT_APP_BASE_URL}api/v2/announce/groups/${announceGroupId}/audience`,

    announceGroupCategories: (organizationId: number) => `${REACT_APP_BASE_URL}api/v2/announce/organizations/${organizationId}/categories`,
    announceGroupTags: (organizationId: number) => `${REACT_APP_BASE_URL}api/v2/announce/organizations/${organizationId}/tags`,

    saveAnnouncement: (announcementId: number) => `${REACT_APP_BASE_URL}api/v2/announce/users/save/${announcementId}/`,
    unsaveAnnouncement: (announcementId: number) => `${REACT_APP_BASE_URL}api/v2/announce/users/unsave/${announcementId}/`,
};

// Endpoints used within pay
export const payEndpoints = {
    pay: `${REACT_APP_BASE_URL}api/v2/pay/`,
    listings: `${REACT_APP_BASE_URL}api/v2/pay/user/home/`,
    createListing: `${REACT_APP_BASE_URL}api/v2/pay/listings/new/`,
    listing: (id: number) => `${REACT_APP_BASE_URL}api/v2/pay/listings/${id}/edit/`,

    requestNewListing: `${REACT_APP_BASE_URL}api/v2/pay/listings/requests/new/`,

    denyNewListingRequest: `${REACT_APP_BASE_URL}api/v2/pay/listings/requests/deny/`,
    approveNewListingRequest: `${REACT_APP_BASE_URL}api/v2/pay/listings/requests/approve/`,

    products: (adminGroupId: number) => `${REACT_APP_BASE_URL}api/v2/pay/products/list/?admin_group_id=${adminGroupId}`,
    createProduct: `${REACT_APP_BASE_URL}api/v2/pay/products/create`,
    updateProduct: `${REACT_APP_BASE_URL}api/v2/pay/products/add-options-variations/`,

    productVariation: `${REACT_APP_BASE_URL}api/v2/pay/products/variations/`,
    productOption: `${REACT_APP_BASE_URL}api/v2/pay/products/options/`,
    productOptionChoice: (productOptionId: number) => `${REACT_APP_BASE_URL}api/v2/pay/products/options/choices/${productOptionId}/choice-cud/`,

    cart: `${REACT_APP_BASE_URL}api/v2/pay/cart/items/`,
    addToCart: `${REACT_APP_BASE_URL}api/v2/pay/cart/items/add/`,
    removeFromCart: (cartItemId: number) => `${REACT_APP_BASE_URL}api/v2/pay/cart/items/${cartItemId}/remove/`,
    updateCartItem: (cartItemId: number) => `${REACT_APP_BASE_URL}api/v2/pay/cart/items/${cartItemId}/update/`,
};

// Endpoints used within ride
export const rideEndpoints = {
    base: () => `${REACT_APP_BASE_URL}api/v2/ride/`,

    updateRideCode: (rideGroupId: number, rideCodeId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${rideGroupId}/ride-codes/${rideCodeId}/update/`,

    groups: () => `${rideEndpoints.base()}groups/`,
    group: (groupId: number) => `${rideEndpoints.groups()}${groupId}/`,
    membership: (groupId: number) => `${rideEndpoints.group(groupId)}members/me/permissions`,

    members: (groupId: number) => `${rideEndpoints.group(groupId)}members/`,
    addMember: (groupId: number) => `${rideEndpoints.members(groupId)}add/`,
    updateMember: (groupId: number, membershipId: number) => `${rideEndpoints.members(groupId)}${membershipId}/`,
    eligibleMembers: (groupId: number) => `${rideEndpoints.members(groupId)}eligible/`,

    pickupSessions: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/`,

    riders: (groupId: number) => `${rideEndpoints.group(groupId)}riders/`,
    rider: (groupId: number, riderId: number) => `${rideEndpoints.riders(groupId)}${riderId}/`,
    eligibleRiders: (groupId: number) => `${rideEndpoints.riders(groupId)}eligible/`,
    addRider: (groupId: number) => `${rideEndpoints.riders(groupId)}add/`,

    rideCode: (groupId: number, rideCodeId: number) => `${rideEndpoints.group(groupId)}ride-codes/${rideCodeId}/`,
    eligibleRideCodeRiders: (groupId: number, rideCodeId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/eligible-riders/`,
    addRidersToRideCode: (groupId: number, rideCodeId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/riders/list-add/`,

    rideCodeRiders: (groupId: number, rideCodeId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/riders/`,

    riderRideCodePDF: (groupId: number, riderId: number) => `${rideEndpoints.rider(groupId, riderId)}pdf/`,

    pickupZones: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/`,

    rideCodes: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/`,
    newRideCode: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/new/`,
    rideCodePDFs: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/pdfs/`,
    scrambleRideCodes: (groupId: number, rideCodeId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/ride-codes/${rideCodeId}/scramble/`,

    actionCodes: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/`,
    actionCodeDetail: (groupId: number, actionCodeId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/${actionCodeId}/`,
    newActionCode: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/action-codes/new/`,

    newPickupZone: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/new/`,
    updatePickupZone: (groupId: number, zoneId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/${zoneId}/`,
    deletePickupZone: (groupId: number, zoneId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-zones/${zoneId}/`,

    session: (sessionId: number) => `${REACT_APP_BASE_URL}api/v2/ride/pickup-sessions/${sessionId}`,
    activeSession: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/active/`,

    startSession: (groupId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/start/`,
    endSession: (groupId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/end/`,
    sessionDetail: (groupId: number, sessionId: number) => `${rideEndpoints.group(groupId)}pickup-sessions/${sessionId}`,

    sessionPickupNotices: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/notices/`,

    stageRider: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/stage/`,
    dispatchRider: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/dispatch/`,
    sendRider: (groupId: number, sessionId: number) => `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/send/`,
    receiveRider: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/receive/`,
    releaseRider: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/release/`,
    removeRider: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/remove/`,
    undoRiderAction: (groupId: number, sessionId: number) =>
        `${REACT_APP_BASE_URL}api/v2/ride/groups/${groupId}/pickup-sessions/${sessionId}/riders/undo-last-action/`,
};

export const signEndpoints = {
    groups: `${REACT_APP_BASE_URL}api/v2/sign/groups/`,
    group: (groupId: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${groupId}/`,

    document: (group_id: number, document_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/documents/${document_id}`,
    documents: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/documents/`,
    newDocument: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/documents/new/`,
    setActivityOfDocuments: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/documents/set-is-active/`,
    deleteDocuments: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/documents/delete/`,

    collection: (group_id: number, collection_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/`,
    collections: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections`,
    newCollection: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/new/`,
    updateCollection: (group_id: number, collection_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/update/`,
    deleteCollection: (group_id: number, collection_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/delete/`,
    addDocumentsToCollection: (group_id: number, collection_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/add-documents/`,
    removeDocumentsFromCollection: (group_id: number, collection_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/document-collections/${collection_id}/remove-documents/`,

    members: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/members/`,
    updateMember: (group_id: number, membership_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/members/${membership_id}/`,
    eligibleMembers: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/members/eligible/`,
    addMember: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/members/add/`,

    signatureRequests: (group_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/`,
    signatureRequest: (group_id: number, signature_request_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/`,
    createSignatureRequest: (signGroupId: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/new/`,

    availableDocuments: (signGroupId: number) => `${REACT_APP_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/available-documents`,

    addRecipientToSignatureRequest: (group_id: number, signature_request_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/add-recipients/`,

    packets: (group_id: number, signature_request_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/packets/`,
    packet: (group_id: number, signature_request_id: number, packet_id: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${group_id}/signature-requests/${signature_request_id}/packets/${packet_id}/`,

    myPackets: `${REACT_APP_BASE_URL}api/v2/sign/users/packets/`,
    signature: `${REACT_APP_BASE_URL}api/v2/sign/users/signature/`,

    signField: (packet_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/users/packets/${packet_id}/sign/`,
    saveField: (packet_id: number) => `${REACT_APP_BASE_URL}api/v2/sign/users/packets/${packet_id}/save/`,

    sendPacket: (signGroupId: number, signatureRequestId: number, packetId: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/${signatureRequestId}/packets/${packetId}/send/`,
    sendPackets: (signGroupId: number, signatureRequestId: number) =>
        `${REACT_APP_BASE_URL}api/v2/sign/groups/${signGroupId}/signature-requests/${signatureRequestId}/send-packets/`,
};
