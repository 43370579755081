import {Column} from '@tanstack/react-table';
import {XCircleIcon} from '@heroicons/react/16/solid';
import {useLocale} from '@react-aria/i18n';

interface ActiveFiltersProps<T> {
  columns: Column<T>[];
}

export default function ActiveFilters<T>({columns}: ActiveFiltersProps<T>) {
	const locale = useLocale();
	const dateToString = (date: any, options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	} as Intl.DateTimeFormatOptions) => {
		return new Intl.DateTimeFormat(locale.locale, options).format(date.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone));
	};

	return (
		<div className={'w-full inline-flex gap-2 ml-5 overflow-x-auto pb-2'}>
			{columns.map((column) => {
				if (column.getIsFiltered()) {
					const filterValue = column.getFilterValue() as string[];
					if (column.columnDef.meta?.data_type === 'range' || column.columnDef.meta?.data_type === 'int') {
						return (
							<div key={column.id}
								className={'flex items-center gap-2 px-2 py-1 bg-primary-50 border border-primary-70 text-neutral-90 rounded-full'}>
								<span>{filterValue[0]} - {filterValue[1]}</span>
								<XCircleIcon className={'h-4 cursor-pointer'}
									onClick={() => {
										column.setFilterValue([]);
									}}/>
							</div>
						);
					} else if (column.columnDef.meta?.data_type === 'date') {
						return (
							<div key={column.id}
								className={'flex items-center gap-2 px-2 py-1 bg-primary-50 text-neutral-90 border border-primary-60 rounded-full'}>
								<span>{dateToString(filterValue[0])} - {dateToString(filterValue[1])}</span>
								<XCircleIcon className={'h-4 cursor-pointer'}
									onClick={() => {
										column.setFilterValue([]);
									}}/>
							</div>
						);
					}
					return filterValue.map((filterValue, index) => {
						return (
							<div key={index}
								className={'flex items-center gap-2 px-2 py-1 bg-primary-50 text-neutral-90 border border-primary-60 rounded-full'}>
								<span>{filterValue ?? '—'}</span>
								<XCircleIcon className={'h-4 cursor-pointer'}
									onClick={() => {
										const currentFilters = column?.getFilterValue() ? [...column?.getFilterValue() as string[]] : [];
										column.setFilterValue(currentFilters.filter((item) => item !== filterValue));
									}}/>
							</div>
						);
					});
				}
				return null;
			})}
		</div>
	);
}